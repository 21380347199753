<header class="header" id="header">
    <div class="logo" routerLink="" (click)="sharedDataService.scrollToSection('ATF')">
        <img src="../../assets/img/logo.png">
    </div>

    <div class="links">
        <a routerLink="" id="header_AM" (click)="sharedDataService.scrollToSection('aboutMe')">Über mich</a>
        <a routerLink="" (click)="sharedDataService.scrollToSection('mySkills')">Skills</a>
        <a routerLink="" (click)="sharedDataService.scrollToSection('portfolio')">Portfolio</a>
        <img (click)="translationService.translate()" src="../../assets/img/translate.png" alt="translate">
    </div>

    <div class="menu_div">
        <img id="open_menu" (click)="openResponsiveMenu()" class="menu" src="../../assets/img/burger menu.png">
        <img id="close_menu" (click)="closeResponsiveMenu()" class="menu d-none" src="../../assets/img/close menu.png">
    </div>
</header>

<div id="header_res_menu" class="header_res_menu d-none">
    <div class="links_res">
        <a id="res_header_AM" routerLink="" (click)="closeResponsiveMenu(); sharedDataService.scrollToSection('aboutMe')">Über mich</a>
        <a routerLink="" (click)="closeResponsiveMenu(); sharedDataService.scrollToSection('mySkills')">Skills</a> 
        <a routerLink=""  (click)="closeResponsiveMenu(); sharedDataService.scrollToSection('portfolio')">Portfolio</a>
        <a routerLink="" (click)="closeResponsiveMenu(); sharedDataService.scrollToSection('contact')" id="contactHeaderRes">Kontakt</a>
        <img (click)="translationService.translate()" src="../../assets/img/translate.png" alt="translate">
    </div>
</div>