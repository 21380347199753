<div class="portDiv" id="portfolio">
    <div data-aos="fade-left" data-aos-anchor-placement="bottom-bottom" class="port_headerDiv">
        <div class="port_headerelement1"></div>
        <span  class="port_header">Portfolio</span>
        <div class="port_headerelement2"></div>
    </div>
    <span data-aos="fade-left" data-aos-anchor-placement="bottom-bottom" class="port_text" id="port_text">
        Diese Seite zeigt eine Auswahl meiner Arbeit! Scrollen Sie nach unten, um einige meiner Projekte anzusehen.
    </span>

    @if (translationService.isGerman) {
        <app-portfolio-element fd="row" imgURL="../../assets/img/join photojoin.png" title="Join"
        skills="JavaScript | HTML | CSS"
        id="description1"
        description="Ein Aufgaben Manager, inspiriert vom Kanban-System. Erstellen und organisieren Sie Aufgaben mithilfe von Drag-and-Drop-Funktionen und weisen Sie diesen Benutzern und Kategorien zu."
        livelink="https://join.kerim-tasci.de/html/login.html"
        githublink="https://github.com/S-KerimTasci/Join-Privat">
        </app-portfolio-element>
    }@else {
        <app-portfolio-element fd="row" imgURL="../../assets/img/join photojoin.png" title="Join"
        skills="JavaScript | HTML | CSS"
        id="description1"
        description="Task manager inspired by the Kanban System. Create and organize tasks using drag and drop functions, assign users and categories."
        livelink="https://join.kerim-tasci.de/html/login.html"
        githublink="https://github.com/S-KerimTasci/Join-Privat">
        </app-portfolio-element>
    }
    
    @if (translationService.isGerman) {
        <app-portfolio-element fd="row-reverse" imgURL="../../assets/img/polloloco.png" title="EL POLLO LOCO"
        skills="JavaScript | HTML | CSS"
        id="description2"
        description="Ein Jump'n'Run-Spiel basierend auf dem objektorientierten Ansatz. Hilf Pepe, Münzen und Salsa zu sammeln, um gegen die verrückte Henne zu kämpfen."
        livelink="https://game.kerim-tasci.de/"
        githublink="https://github.com/S-KerimTasci/Game">
        </app-portfolio-element>
    }
    @else {
        <app-portfolio-element fd="row-reverse" imgURL="../../assets/img/polloloco.png" title="EL POLLO LOCO"
        skills="JavaScript | HTML | CSS"
        id="description2"
        description="Jump, run and throw game based on object-oriented approach. Help Pepe to find coins and salsa to fight against the crazy hen."
        livelink="https://game.kerim-tasci.de/"
        githublink="https://github.com/S-KerimTasci/Game">
        </app-portfolio-element>
    }
</div>