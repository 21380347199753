<div data-aos="fade-right" class="conatctHeaderDiv" id="contact">
    <div class="conatctHeaderElement"></div>
    <span class="conatctHeader" id="conatctHeader">Kontakt</span>
</div>

<div class="contactDiv">
    @if (emailSent) {
        <div class="emailSent">
            @if (translationService.isGerman) {
                <p>Vielen Dank für Ihre Email. <br> Ich werde mich in kürze bei Ihnen melden</p>
            }@else{
                <p>Thank you for your email. <br> I will contact you shortly.</p>
            }
        </div>
    }
    
    
    <div data-aos="fade-right" data-aos-anchor-placement="center-bottom" class="contactTextDiv">
        <span class="contactTextH2" id="problemHeader">Auf der Suche nach einer Lösung für Ihr Problem?</span>
        <span class="contactText" id="contactText">
            Kontaktieren Sie mich. Ich freue mich darauf, von Ihnen zu hören. Ich bin gespannt darauf, mehr über Ihre
            Ideen zu erfahren und dazu beizutragen, Ihre Projekte mit meiner Arbeit voranzubringen.
        </span>
        <span class="contactText" id="contactText2">
            Brauchen Sie einen Frontend Entwickler? <b>Kontaktieren Sie mich!</b>
        </span>
    </div>


    <form ngNativeValidate data-aos="fade-left" data-aos-anchor-placement="center-bottom" class="contactForm"
        (submit)="onSubmit(contactForm)" #contactForm="ngForm">
        <div class="contactFormElement">
            <input #name="ngModel" [(ngModel)]="contactData.name" required type="text" name="name" placeholder="Name" id="nameField"
                #nameField>
            @if (!name.valid && name.touched) {
                @if (translationService.isGerman) {
                    <span>Bitte Namen hinzufügen</span>
                }@else {
                    <span >Your name is required</span>
             }}
        </div>

        <div class="contactFormElement">
            <input #mail="ngModel" [(ngModel)]="contactData.email" required pattern="^.+@.+\..{2,}$" type="email" name="mail"
                placeholder="Email" id="emailField">
            @if (!mail.valid && mail.touched) {
                @if (translationService.isGerman) {
                    <span>Bitte Email hinzufügen</span>
                }@else {
                    <span>Your email is required</span>
            }}
        </div>

        <div class="contactFormElement">
            <textarea #message="ngModel" [(ngModel)]="contactData.message" required name="message" id="" cols="30"
                rows="10" placeholder="Nachricht" id="messageField">
            </textarea>
            @if (!message.valid && message.touched) {
                @if (translationService.isGerman) {
                    <span>Bitte Nachricht hinzufügen</span>
                }@else {
                    <span>Your message is empty</span>
            }}
        </div>

        <div class="checkboxDiv">
            <input (click)="checkCheckbox()" #checkbox required type="checkbox" id="cb1">
            <label for="cb1" id="formLabel">
                @if (translationService.isGerman) {
                Ich habe die
                <a routerLink="imprint"
                    (click)="sharedDataService.scrollToSection('privacyPolicy')">Datenschutzrichtlinie</a>
                gelesen und stimme der Verarbeitung meiner Daten gemäß den Bestimmungen zu.
                }@else {
                I've read the
                <a id="formLabelLink" routerLink="imprint"
                    (click)="sharedDataService.scrollToSection('privacyPolicy')">privacy policy</a>
                and agree to the processing of my data as outlined.
                }
            </label>
        </div>

        <div class="BTNdiv">
            <button [disabled]="!(contactForm.valid && checkboxClicked)" type="submit" id="sendButton" #sendButton>Senden</button>
        </div>
    </form>


    <img class="goUpBtnOrange" (click)="sharedDataService.scrollToSection('ATF')"
        src="../../assets/img/goupbtnorange.png">
</div>