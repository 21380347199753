<footer id="footer">
    <div class="logoImprintDiv">
        <div class="logo" routerLink="">
            <img src="../../assets/img/logo.png">
        </div>
        <p class="imprintText" routerLink="imprint" (click)="sharedDataService.scrollToSection('imprint')" id="footerImprintRes">Impressum</p>
    </div>
    <span>
        &#169; Kerim Tasci 2024 
    </span>
    <div class="socials">
        <div class="ATF_styleElement"></div>

        <a (click)="visitPageService.visitPage(visitPageService.gitHubLink)"><img class="ATF_icon" src="../../assets/img/github.png"></a>
        <a href="mailto:contact@kerim-tasci.de" ><img class="ATF_icon" src="../../assets/img/email.png"></a>
        <a (click)="visitPageService.visitPage(visitPageService.linkedInLink)"><img class="ATF_icon" src="../../assets/img/linkedin.png"></a>
    </div>

    <p class="imprintTextRes" routerLink="imprint" (click)="sharedDataService.scrollToSection('imprint')" id="footerImprint">Impressum</p>
</footer>
