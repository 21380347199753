<div class="aboutMeDiv" id="aboutMe">
    <div data-aos="fade-right" class="aboutMeText">
        <span class="AM_header" id="AM_header">Über mich</span>
        <p class="AM_text" id="AM_text1">Hallo, mein Name ist Kerim Tasci und ich bin ein Frontend Entwickler aus
            München. Mit
            Leidenschaft für das Programmieren habe ich mich auf Sprachen wie <b>JavaScript</b>, Frameworks wie
            <b>Angular</b> und die Arbeit mit <b>REST-API</b> spezialisiert.
        </p>
        <div class="imgWithText">
            <img src="../../assets/img/AM_bulb.png">
            <p class="AM_text" id="AM_text2">
                Ich sehe Code nicht einfach als Zeilen auf einem Bildschirm, sondern als ein Mittel, um reale Probleme
                zu lösen. Als engagierter Problemlöser bringe ich Kreativität und Präzision in jedes Projekt ein, das
                ich angehe.
            </p>
        </div>
        <div class="imgWithText">
            <img src="../../assets/img/AM_puzzle.png">
            <p class="AM_text" id="AM_text3">
                Auf dieser Seite präsentiere ich einige meiner Projekte, die meine Fähigkeiten als Frontend Entwickler
                widerspiegeln. Schreiben Sie mir gerne, wenn Sie auf der Suche nach einem motivierten Junior Frontend
                Entwickler sind.
            </p>
        </div>
    </div>

    <div data-aos="fade-left" class="aboutMePicture">
        <div class="circle"></div>
        <div class="line"></div>
    </div>
</div>