<div data-aos="zoom-in-down" data-aos-anchor-placement="bottom-bottom"
     class="portfolioElementDiv" [style.flex-direction]="fd">
    <div class="portfolioElementImgDiv">
        <img src="{{ imgURL }}" alt="{{ imgALT }}">
    </div>
    <div class="portfolioElementTextDiv">
        <span class="header">{{ title }}</span>
        <span class="skills">{{ skills }}</span>
        <span id="{{ id }}" class="description">{{ description }}</span>
        <div class="buttonDiv">
            <button (click)="visitPageService.visitPage(livelink)" class="livetestBTN">Live test</button>
            <button (click)="visitPageService.visitPage(githublink)" class="githubBTN">Github</button>
        </div>
    </div>
</div>