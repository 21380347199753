<div class="mySkillsDiv" id="mySkills">
    <div class="MS_button_res_div">
        <button class="MS_button_res" (click)="sharedDataService.scrollToSection('contact')">Get in touch</button>
    </div>

    <div data-aos="fade-right" class="iconDiv">
        <div *ngFor="let image of images; let i = index" class="image-container">
            <img src="../../assets/img/mySkillsIcons/{{image}}" alt="Image {{ i + 1 }}">
            <p>{{ titles[i] }}</p>
        </div>
    </div>

    <div data-aos="fade-left" class="textDiv">
        <div class="MS_headerDiv">
            <span class="MS_header">My skills</span>
            <div class="MS_headerElement"></div>
        </div>
        <span class="MS_text" id="MS_text">
            Während meiner Arbeit an verschiedenen Einzel- und Gruppenprojekten konnte ich mich mit den folgenden
            Technologien vertraut machen. 
        </span>
        <div class="BTNdiv">
            <button (click)="sharedDataService.scrollToSection('contact')">@if (translationService.isGerman) {Kontakt} @else {Get in touch} </button>
        </div>
    </div>

</div>